<template>
  <v-container>
    <router-view></router-view>
    <caixaqui-footer></caixaqui-footer>
  </v-container>
</template>

<script>
import CaixaquiFooter from "../../components/caixaqui/CaixaquiFooter";

export default {
  name: "CaixaquiMain",
  components: {
    CaixaquiFooter
  }
};
</script>

<style scoped></style>
