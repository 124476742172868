<template>
  <v-card class="d-flex justify-center caixaqui-footer caixaqui-footer-font" flat tile>
    <span class="caixaqui-footer-font-title">Código do Convênio</span>
    <span class="mx-4">000000000</span>
    <span class="mr-4 caixaqui-footer-font-title">Identificação do Operador</span>
    <span>{{ operador }}</span>
  </v-card>
</template>

<script>
export default {
  name: "CaixaquiFooter",
  computed: {
    operador() {
      return this.$store.getters["auth/profile"].name;
    }
  }
};
</script>

<style scoped>
.caixaqui-footer {
  background-color: #fafafa !important;
  margin: 30px 0 30px 0;
}

.caixaqui-footer-font {
  font: 12px Arial;
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-weight: normal;
  font-stretch: normal;
}
.caixaqui-footer-font-title {
  color: #0c469d;
}
</style>
