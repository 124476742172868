var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "d-flex justify-center caixaqui-footer caixaqui-footer-font",
      attrs: { flat: "", tile: "" }
    },
    [
      _c("span", { staticClass: "caixaqui-footer-font-title" }, [
        _vm._v("Código do Convênio")
      ]),
      _c("span", { staticClass: "mx-4" }, [_vm._v("000000000")]),
      _c("span", { staticClass: "mr-4 caixaqui-footer-font-title" }, [
        _vm._v("Identificação do Operador")
      ]),
      _c("span", [_vm._v(_vm._s(_vm.operador))])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }